import React from 'react'
import classes from './SocialLinks.module.scss'
import {
  Github,
  Phone,
  Mail,
  LinkedIn,
  Medium
} from '../../media/index.jsx'

const SocialLinks = () => {
  return (
    <div className={classes.SocialLinksContainer}>
      <a href="https://github.com/stellenberger/CV" className={classes.link}><img src={Github} alt="github-octocat" className={classes.socialLinks}/></a>
      <a href="#projects" className={classes.link}><img src={Mail} alt="mail-symbol" className={classes.socialLinks}/></a>
      <a href="https://www.linkedin.com/in/stephan-ellenberger-2ba0911aa/" className={classes.link}><img src={LinkedIn} alt="linkedin-logo" className={classes.socialLinks}/></a>
      <a href="https://medium.com/@s.ellenberger95" className={classes.link}><img src={Medium} alt="medium-logo" className={classes.socialLinks}/></a>
      <a href="#contact" className={classes.link}><img src={Phone} alt="phone-logo" className={classes.socialLinks}/></a>
    </div>
  )
}

export default SocialLinks
