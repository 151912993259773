import React from 'react'
import classes from './Experience.module.scss'
import ExperienceSection from './experience_section/ExperienceSection.jsx'
import {
  DfEDigital,
  NowU,
  CodeRoots,
} from '../../media/index.jsx'

const Experience = () => {
  const DfEBlurb = "Worked as a Ruby Developer on multiple projects such as Register Trainee Teachers, Teacher Training API, Publish Teacher Training; all part of the Becoming a Teacher Journey, DfE Digitals largest initiative at the time of writing. Working with a multi-disciplinary team of User Researchers, Content Designers, Developers and stakeholders, the outputted work was User Centric, Agile and iterative, with every development being unit and feature tested with Rspec and Capybara and rigorously code reviewed before being pushed to production. Also worked on deploying the Service Manual to GovUK PaaS using GitHub Actions and Cloud Foundry, as well as working toward Azure AZ900 Certification."
  const NowUBlurb = "Worked as a Ruby on Rails developer before switching to lead the Front End Team (ReactJS) to push through their V2 launch. After the launch of V2, I moved back to the API team as Lead Developer and have delivered Agile, tested work, implementing code to support both the website and the App."
  const CodeRootsBlurb = "Founded by a Makers Academy Graduate, I was hired as a in house React Developer, constructing Figma designs into React code for both Mobile and Web ahead of their fundraising launce, with the aim of raising £25,000 for coding schools in Gambia"

  return (
    <div className={classes.experienceComponentContainer} id='projects'>
      <ExperienceSection imageLogo={DfEDigital} title={"Department for Education"} location={"Westminster, London"} blurb={DfEBlurb}/>
      <ExperienceSection imageLogo={NowU} title={"Now U"} location={"Remote, United Kingdom"} blurb={NowUBlurb} />
      <ExperienceSection imageLogo={CodeRoots} title={"Coderoots"} location={"Remote, United Kingdom"} blurb={CodeRootsBlurb} />
    </div>
  )
}

export default Experience
