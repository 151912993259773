import React, { useEffect, useState } from "react";
import classes from "./App.module.scss";
import {
  TopNav,
  Landing,
  Technology,
  Experience,
  About,
  Projects,
  SocialLinks,
} from "./components";
import "pure-react-carousel/dist/react-carousel.es.css";
import ReactGa from "react-ga";
import {
  CarouselProvider,
  Dot,
  DotGroup,
  Slide,
  Slider,
  ButtonNext,
  ButtonBack,
} from "pure-react-carousel";

function App() {
  const [currentSlideNumber, setCurrentSlideNumber] = useState(0);

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.code == "ArrowRight") {
        nextSlide();
      }
      if (event.code == "ArrowLeft") {
        prevSlide();
      }
    });
    ReactGa.initialize("UA-175013754-1");

    // to report page view
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  const nextSlide = () => {
    setCurrentSlideNumber((currentSlideNumber) => currentSlideNumber + 1);
  };

  const prevSlide = () => {
    setCurrentSlideNumber((currentSlideNumber) => currentSlideNumber - 1);
  };

  return (
    <div className={classes.App}>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={150}
        totalSlides={3}
        currentSlide={currentSlideNumber}
        dragEnabled={false}
        touchEnabled={false}
      >
        <SocialLinks />
        <div className={classes.container}>
          <Slider>
            <Slide className={classes.slide} index={0}>
              <Landing />
            </Slide>
            <Slide className={classes.slide} index={1}>
              <About />
            </Slide>
            <Slide className={classes.slide} index={2}>
              <Technology />
            </Slide>
            {/* <Slide className={classes.slide} index={3}>
              <Experience />
            </Slide> */}
            {/* <Slide className={classes.slide} index={4}>
              <Projects />
            </Slide> */}
          </Slider>
        </div>
        <DotGroup className={classes.dotGroup} />
        <ButtonBack className={classes.buttonBack}>
          <i class={classes.left} />
        </ButtonBack>
        <ButtonNext className={classes.buttonNext}>
          <i class={classes.right} />
        </ButtonNext>
      </CarouselProvider>
    </div>
  );
}

export default App;
