import classes from './About.module.scss';
import React from 'react'
import {
  Steph
} from '../../media/index.jsx'

export default function About() {
  const backgroundImage = {
    backgroundImage: `url(${Steph})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }

  return (
    <div className={classes.aboutContainer}>
      <img src={Steph} alt="Picture of Stephan Ellenberger" className={classes.stephPhoto}/>
      <div className={classes.contentContainer}>
        <p>Born in South Africa, raised in Australia and educated in the UK, I was trained as a Classical Pianist at Trinity College of Music in London, and then Makers Academy to learn how to code. I’ve always been intrigued by digital products, and in 2018 I started an online business, which promptly led to my enrolment at Makers Academy to explore the world as a Software Developer.</p> 
        <p>Since graduating Makers in 2020, I currently work at the Department for Education and volunteer my skills at now-u, a UK based non-profit, as API Tech Lead. Alongside this, I have completed several freelance projects, personal projects and am passionate about automation and cloud services.</p>
        <p>Aside from my technical passion, you can find me deviating from any path if a cat is in sight, travelling and always planning my next meal.</p>
      </div>
    </div>
  )
}
