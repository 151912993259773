import React from "react";
import classes from "./Landing.module.scss";

const Landing = () => {
  return (
    <div className={classes.landingContainer}>
      <div className={classes.welcomeMessage}>
        <p className={classes.landingName}>
          <span className={classes.name}>Stephan Ellenberger</span>.
        </p>
        <p className={classes.landingContent}>
          Full Stack NodeJS and Java Developer in Amsterdam.
        </p>
        <p className={classes.landingContent}>Just Eat Takeaway.</p>
      </div>
    </div>
  );
};

export default Landing;
