import React from 'react'
import classes from './Technology.module.scss'
import {
  LanguagesSVG,
  FrameworksSVG, 
  WorkflowsSVG, 
  technology_intro,
} from '../../media/index.jsx'

const Technology = () => {
  const skills = [
    {id: 1, type: 'Technologies', names: [
      'Java (Spring Boot, Maven)', 
      'Ruby (Ruby on Rails)', , 
      'ReactJS', 
      'JavaScript (ES5 & ES7)', 
      'Docker', 
      'Cloud Computing [Cloud Foundry, AWS, PaaS]', 
    ]},
    {id: 2, type: 'Workflows', names: [
      'Agile', 
      'TDD & BDD', 
      'Git & GitHub',
      'Modern standard acronyms', 
      'Modern Design Patterns',
      'Efficient code',
    ]}
  ]
  return (
    <div className={classes.technologyContainer} id='technology'>
      <img src={technology_intro} alt="Technology and Workflows" />
      {skills && skills.map((skill) => {
        return (
          <div className={classes.skillContainer}>
            <h3>{skill.type}</h3>
            {skill.names.map((name) => {
              return <li>{name}</li>
            })}
          </div>
        )
      })}
    </div>
  )
}

export default Technology
